import { ApplicationType } from "./application-type";
import { Breadcrumb } from "./breadcrumb";

export enum Route {
    DASHBOARD = '',
    JOIN_US = 'join-us',
    REVENUE = 'revenue',
    SOMETHING_ELSE = 'something-else',
    NOT_FOUND = 'not-found'
}

export const ROUTE_MAP = {
    [ApplicationType.RND]: Route.REVENUE,
    [ApplicationType.JU_CARSON]: Route.JOIN_US,
    [ApplicationType.SE]: Route.SOMETHING_ELSE,
}

export const BREADCRUMB_CONFIG: Record<Route, Breadcrumb[]> = {
    [Route.REVENUE]: [
        { path: Route.DASHBOARD, label: 'Home' },
        { label: 'Rendite' },
    ],
    [Route.JOIN_US]: [
        { path: Route.DASHBOARD, label: 'Home' },
        { label: 'Join Us' },
    ],
    [Route.SOMETHING_ELSE]: [
        { path: Route.DASHBOARD, label: 'Home' },
        { label: 'Somenthing Else' },
    ],
    [Route.NOT_FOUND]: [
        { path: Route.DASHBOARD, label: 'Home' },
        { label: 'Not Found' },
    ],
    [Route.DASHBOARD]: [],
}