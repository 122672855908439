export enum ApplicationType {
    RND = 'RND',
    JU_CARSON = 'JU_CARSON',
    SE = 'SE'
}

export interface ApplicationObj {
    id: ApplicationType;
    title: string;
    description?: string;
    logo_url?: string;
}